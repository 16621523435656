import React, { useState } from "react";
import "./Footer.css";
import motomart from "../../Assets/img/motomart.png";
import { Form, Button, Spinner } from "react-bootstrap";
import { FiFacebook, FiInstagram, FiTwitter } from "react-icons/fi";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import { Link } from "react-router-dom";
import { useContext } from "react";
import ContactContext from "../../Context/contact/contactContext";
import toast from "react-hot-toast";

function Footer() {
  const contactContext = useContext(ContactContext);

  const [contact, setContact] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const { name, email, phone, message } = contact;

  const onChange = (e) => {
    e.preventDefault();
    setContact({ ...contact, [e.target.name]: e.target.value });
  };

  const clearForm = () => {
    setContact({
      name: "",
      email: "",
      phone: "",
      message: "",
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();

    saveContact(contact, clearForm);
  };

  const { saveContact, loading } = contactContext;
  return (
    <div className='footer-container p-3 container-fluid' id='contact'>
      <FloatingWhatsApp
        style={{ zIndex: "10000" }}
        className='whatsapp'
        phoneNumber='+2348039999989'
        accountName='Motomart Careline'
        allowClickAway={true}
        statusMessage='Typically replies within 5 mins'
        // avatar={motomart}
      />
      <div className='d-flex row justify-content-evenly px-5 py-3 align-items-center '>
        <div
          className='col-md-6 col-xs-12 gy-xs-3 f4 '
          style={{ width: "40rem" }}
        >
          <div className=' row l3'>
            <div className='col-md-6 ll d-flex'>
              <a href='/'>
                <img
                  src={motomart}
                  alt='motomart-logo'
                  className='motomart-logo'
                />
              </a>
            </div>

            <div className='icons text-white d-flex col-md-6 align-items-end justify-content-evenly fs-4 '>
              <a
                href='https://www.facebook.com/motomartNIG'
                className='text-white'
                target='_blank'
                rel='noopener noreferrer'
              >
                <FiFacebook />
              </a>
              <a
                href='https://www.instagram.com/motomart.ng/'
                className='text-white'
                target='_blank'
                rel='noopener noreferrer'
              >
                <FiInstagram />
              </a>
              <a
                href='https://twitter.com/motomartnig'
                className='text-white'
                target='_blank'
                rel='noopener noreferrer'
              >
                <FiTwitter />
              </a>
            </div>
          </div>
          <div className='info-a d-md-flex p-3 row my-5 text-center'>
            <div className='col-md-6 list1'>
              <ul className=''>
                <a href='/'>
                  <li className='py-2 fw-bold text-uppercase'>Home</li>
                </a>
                {/* <a href="#all-product">
                  <Link to="/all-product" href="#all-product">
                    <li className="py-2 fw-bold">OUR PRODUCT</li>
                  </Link>
                  <Link
              to="/all_product"
              className="text-white text-decoration-none navbar_links"
            >
              ALL PRODUCTS
            </Link>
                </a> */}
                <Link
                  to='/all_product'
                  href='#all-product'
                  style={{ fontSize: "0.8rem" }}
                  className=' fw-bold text-decoration-none'
                >
                  ALL PRODUCTS
                </Link>
                <a href='#contact'>
                  <li className='py-2 fw-bold'>CONTACT US</li>
                </a>
              </ul>
            </div>
            <div className='col-md-6 sec2 text-center'>
              <p className='contactText fw-bold'>
                Contact:
                <br />
                <span className=' justify-content-center'>
                  +234 907 943 6768;
                </span>
                <br />
                <span>+234 901 823 7998</span>
              </p>
              <div>
                <p className='addressText fw-bold'>
                  Address:
                  <br />
                  <span className='float-right justify-content-center officeAddress'>
                    Plot A9 Abuja Street, C Close, Banana Island, Lagos.
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          className='col-md-6 col-xs-12 px-3 info'
          style={{ width: "40rem" }}
        >
          {/* <div className='form_head'>
            <div className='w-100 '>
              <input
                type='text'
                placeholder='Full Name'
                className=' p-2 form_g text g5 p-3 fullnameOnForm'
              ></input>
            </div>
          </div> */}
          {/* <input
            type='email'
            id='email'
            placeholder='Email Address'
            className='w-100  text-white z1 p-3'
          ></input>
          <input
            type='tel'
            id='phone'
            placeholder='Phone Number'
            className='w-100 my-3 text-white p-3'
          ></input>
          <textarea
            className='w-100 mb-2 p-3'
            placeholder='Let us know how we can help ...'
            id='Textarea1'
            rows='2'
          ></textarea>
          <button
            className='btn10 text-white d-flex'
            type='submit'
            onClick={() => saveContact()}
            style={{ fontSize: "25px" }}
          >
            Shop Now
          </button> */}
          <Form onSubmit={(e) => onSubmit(e)}>
            <Form.Group className='mb-3' controlId='name'>
              <Form.Control
                type='text'
                placeholder='Full Name'
                className='p-2 form_g text g5 p-3 fullnameOnForm form-control rounded-0 shadow-none border-0 contact-form__controls'
                name='name'
                value={name}
                onChange={onChange}
              />
            </Form.Group>
            <Form.Group className='mb-3' controlId='email'>
              <Form.Control
                type='email'
                placeholder='Email'
                className='p-2 form_g text g5 p-3 fullnameOnForm form-control rounded-0 shadow-none border-0 contact-form__controls'
                name='email'
                value={email}
                onChange={onChange}
              />
            </Form.Group>
            <Form.Group className='mb-3' controlId='phone'>
              <Form.Control
                type='text'
                minLength={11}
                maxLength={11}
                placeholder='Phone e.g. 1234568901'
                className='p-2 form_g text g5 p-3 fullnameOnForm form-control rounded-0 shadow-none border-0 contact-form__controls'
                name='phone'
                value={phone}
                onChange={onChange}
              />
            </Form.Group>
            <Form.Group controlId='exampleForm.ControlTextarea1'>
              <Form.Control
                as='textarea'
                rows={5}
                className='mb-3 p-2 form_g text g5 p-3 fullnameOnForm form-control rounded-0 shadow-none border-0 contact-form__controls'
                placeholder='Enter message'
                name='message'
                value={message}
                onChange={onChange}
              />
            </Form.Group>
            <Button
              variant='primary'
              type='submit'
              className='btn10 text-white d-flex py-3 px-5'
              disabled={name === "" || email === "" || message === ""}
              // style={{ fontSize: "25px" }}
            >
              {loading ? <Spinner animation='border' size='sm' /> : "Submit"}
            </Button>
          </Form>
        </div>
      </div>
      <div className='d-flex text-center justify-content-center bottom'>
        <p>Copyright 2022. Motomart by Sujimoto.</p>
      </div>
    </div>
  );
}

export default Footer;
