import { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import sanitary_wares from "../Assets/sanitary--wares.png";
import toilet_red from "../Assets/_DSC9308 3.png";
import toilet_black from "../Assets/_DSC9308 black 1.png";
import toilet_blue from "../Assets/_DSC9308 blue.png";

function Hero_section() {
  const [toilet, setToilet] = useState(toilet_black);
  const [on, setOn] = useState("black");

  const selectToilet = (toilet) => {
    return setToilet(toilet);
  };
  const black = (e) => (e.target.className !== "black" ? "" : setOn("black"));

  const red = (e) => (e.target.className !== "red" ? "" : setOn("red"));

  const blue = (e) => (e.target.className !== "blue" ? "" : setOn("blue"));

  return (
    <>
      <Swiper
        loop={true}
        // spaceBetween={9}
        autoplay={{ delay: 10000, disableOnInteraction: true }}
        navigation={true}
        grabCursor={true}
        modules={[Navigation, Autoplay]}
        className="product-images-slider"
      >
        <SwiperSlide>
          <div className="container-fluid hero-wrapper">
            <div className="row hero_wrapper">
              <div className="col-6 hero_container">
                <img
                  src={sanitary_wares}
                  alt="sanitary wares"
                  height={210}
                  className="sanitary-wares-on-product-page"
                />
                <h4 className="my-3 all_products_page_text" style={{}}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Vestibulum varius lacus nisl, non tincidunt sapien vehicula
                  in. Aliquam erat volutpat. Aliquam ante tortor, bibendum non
                  ullamcorper eget, sagittis vel diam. Vivamus pellentesque
                  lectus odio, at sagittis felis gravida sit amet. Proin vel
                  purus posuere, gravida justo non, ultricies arcu.
                </h4>
                <div className="colors_wrapper">
                  <h4
                    className="text-uppercase my-4 color_text"
                    style={{ fontSize: "1.2rem" }}
                  >
                    colors
                  </h4>
                  <div
                    className="d-flex justify-content-between w-25 colors-wrapper"
                    style={{ height: "20px" }}
                  >
                    <div
                      className="black"
                      style={{
                        border: on === "black" ? "1px solid white" : "none",
                      }}
                      onClick={(e) => {
                        selectToilet(toilet_black);
                        black(e);
                      }}
                    ></div>
                    <div
                      className="red"
                      style={{
                        border: on === "red" ? "1px solid white" : "none",
                      }}
                      onClick={(e) => {
                        selectToilet(toilet_red);
                        red(e);
                      }}
                    ></div>
                    <div
                      className="blue"
                      style={{
                        border: on === "blue" ? "1px solid white" : "none",
                      }}
                      onClick={(e) => {
                        selectToilet(toilet_blue);
                        blue(e);
                      }}
                    ></div>
                  </div>
                </div>
              </div>
              <div className="col-6  d-flex justify-content-center align-items-end toilet_wrapper">
                <img
                  src={`${toilet}`}
                  alt="toilet"
                  height={400}
                  className="toilet_on_all_products_page"
                />
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="container-fluid hero-wrapper">
            <div className="row hero_wrapper">
              <div className="col-6 hero_container">
                <img
                  src={sanitary_wares}
                  alt="sanitary wares"
                  className="sanitary-wares-on-product-page"
                />
                <h4 className="my-3 all_products_page_text">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Vestibulum varius lacus nisl, non tincidunt sapien vehicula
                  in. Aliquam erat volutpat. Aliquam ante tortor, bibendum non
                  ullamcorper eget, sagittis vel diam. Vivamus pellentesque
                  lectus odio, at sagittis felis gravida sit amet. Proin vel
                  purus posuere, gravida justo non, ultricies arcu.
                </h4>
                <div className="colors_wrapper">
                  <h4 className="text-uppercase my-4 color_text">colors</h4>
                  <div
                    className="d-flex justify-content-between w-25"
                    style={{ height: "20px" }}
                  >
                    <div
                      className="black"
                      onClick={(e) => {
                        selectToilet(toilet_black);
                        black(e);
                      }}
                      style={{
                        border: on === "black" ? "1px solid white" : "none",
                      }}
                    ></div>
                    <div
                      className="red"
                      onClick={(e) => {
                        selectToilet(toilet_red);
                        red(e);
                      }}
                      style={{
                        border: on === "red" ? "1px solid white" : "none",
                      }}
                    ></div>
                    <div
                      className="blue"
                      onClick={(e) => {
                        selectToilet(toilet_blue);
                        blue(e);
                      }}
                      style={{
                        border: on === "blue" ? "1px solid white" : "none",
                      }}
                    ></div>
                  </div>
                </div>
              </div>
              <div className="col-6  d-flex justify-content-center align-items-end">
                <img
                  src={`${toilet}`}
                  alt="toilet"
                  height={450}
                  className="toilet_on_all_products_page"
                />
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </>
  );
}

export default Hero_section;
