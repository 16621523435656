import { toast } from "react-hot-toast";
import {
  SUBMIT_CONTACT,
  CONTACTS_LOADING,
  CLEAR_CONTACTS_LOADING,
} from "../types";

const cartReducer = (state, action) => {
  switch (action.type) {
    case SUBMIT_CONTACT:
      return {
        ...state,
      };
    case CONTACTS_LOADING:
      return {
        ...state,
        loading: true,
      };

    case CLEAR_CONTACTS_LOADING:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};

export default cartReducer;
