import wall from "../../../Assets/img/wall.png";

function Wall() {
  return (
    <>
      <img src={wall} alt="wall product" className="wallImg" />
    </>
  );
}

export default Wall;
