import format from "date-fns/format";

const formatAmount = (amount, maxDigits = 3) =>
  new Intl.NumberFormat("en-NG", {
    style: "currency",
    currency: "NGN",
    maximumSignificantDigits: maxDigits,
  }).format(amount);

const formatAmount2 = (amount, currency = true) =>
  new Intl.NumberFormat(
    "en-NG",
    currency ? { style: "currency", currency: "NGN" } : ""
  ).format(amount);

const formatToYMDHMS = (d) => format(new Date(d), "yyyy-MM-dd HH:mm:ss");

export { formatAmount, formatAmount2, formatToYMDHMS };
