import { useState } from "react";
import AllProducts from "./All-Products";
import Products_List from "./Products_List";
import Dropdown from "react-bootstrap/Dropdown";

function Products() {
  const [products, setProducts] = useState(12);
  const [allProducts, setAllProducts] = useState(Products_List);
  const [isActive, setIsActive] = useState("ALL-PRODUCTS");

  const showAllProducts = () => {
    setProducts(Products_List.length);
  };

  const allProduct = (e) => {
    if (e.target.className !== "ALL-PRODUCTS") {
      setAllProducts(Products_List);
      setIsActive("ALL-PRODUCTS");
    } else {
      setIsActive();
    }
  };

  const sanitaryWareFilter = (e) => {
    if (e.target.className !== "SANITARY-WARES") {
      setAllProducts(
        Products_List.filter((product) => product.category === "sanitary wares")
      );
      setIsActive("SANITARY-WARES");
    } else {
      setIsActive();
    }
  };

  const doorFilter = (e) => {
    if (e.target.className !== "DOORS") {
      setAllProducts(
        Products_List.filter((product) => product.category === "doors")
      );
      setIsActive("DOORS");
    } else {
      setIsActive();
    }
  };

  const buildingMaterialFilter = (e) => {
    if (e.target.className !== "BUILDING-MATERIALS") {
      setAllProducts(
        Products_List.filter(
          (product) => product.category === "building materials"
        )
      );
      setIsActive("BUILDING-MATERIALS");
    }
  };

  const funitureFilter = (e) => {
    if (e.target.className !== "FUNITURES") {
      setAllProducts(
        Products_List.filter((product) => product.category === "funitures")
      );
      setIsActive("FUNITURES");
    }
  };

  return (
    <div className="products">
      <div className="products-warpper">
        {/* Mobile Dropdown */}
        <Dropdown className="mobile_screen ">
          <Dropdown.Toggle>
            {`${isActive}`}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item
              className="all-products"
              onClick={(e) => allProduct(e)}
            >
              ALL PRODUCTS
            </Dropdown.Item>
            <Dropdown.Item
              className="sanitary-wares"
              onClick={(e) => sanitaryWareFilter(e)}
            >
              SANITARY WARES
            </Dropdown.Item>
            <Dropdown.Item
              ClassName="doors"
              onClick={(e) => {
                doorFilter(e);
              }}
            >
              DOORS
            </Dropdown.Item>
            <Dropdown.Item
              ClassName="building-materials"
              onClick={(e) => {
                buildingMaterialFilter(e);
              }}
            >
              BUILDING MATERIALS
            </Dropdown.Item>
            <Dropdown.Item
              ClassName="funitures"
              onClick={(e) => {
                funitureFilter(e);
              }}
            >
              FUNITURES
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        {/* Mobile Dropdown */}

        <div className="buttons-container">
          <div className="d-flex justify-content-between align-items-end w-75 buttons--container">
            <button
              className="buttons ALL-PRODUCTS"
              style={{
                backgroundColor:
                  isActive === "ALL-PRODUCTS" ? "white" : "transparent",
                color: isActive === "ALL-PRODUCTS" ? "#462828" : "white",
              }}
              onClick={(e) => allProduct(e)}
            >
              All products
            </button>
            <button
              className="buttons SANITARY-WARES"
              style={{
                backgroundColor:
                  isActive === "SANITARY-WARES" ? "white" : "transparent",
                color: isActive === "SANITARY-WARES" ? "#462828" : "white",
              }}
              onClick={(e) => sanitaryWareFilter(e)}
            >
              sanitary wares
            </button>
            <button
              className="buttons DOORS"
              style={{
                background: isActive === "DOORS" ? "white" : "transparent",
                color: isActive === "DOORS" ? "#462828" : "white",
              }}
              onClick={(e) => {
                doorFilter(e);
              }}
            >
              doors
            </button>
            <button
              className="buttons BUILDING-MATERIALS"
              style={{
                background:
                  isActive === "BUILDING-MATERIALS" ? "white" : "transparent",
                color: isActive === "BUILDING-MATERIALS" ? "#462828" : "white",
              }}
              onClick={(e) => buildingMaterialFilter(e)}
            >
              building materials
            </button>
            <button
              className="buttons FUNITURES"
              style={{
                background: isActive === "FUNITURES" ? "white" : "transparent",
                color: isActive === "FUNITURES" ? "#462828" : "white",
              }}
              onClick={(e) => funitureFilter(e)}
            >
              funitures
            </button>
          </div>
        </div>
      </div>
      <AllProducts
        products={products}
        showAllProducts={showAllProducts}
        allProducts={allProducts}
      />
    </div>
  );
}

export default Products;
