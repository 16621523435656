import basin from "../../../Assets/img/basin.png";

function Basin() {
  return (
    <>
      <div className="basinTapCircle"></div>
      <div className="basinCircle"></div>
      <img src={basin} alt="basin" className="basin " />
    </>
  );
}

export default Basin;
