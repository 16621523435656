import chair2 from "../../../Assets/img/chair2.png";

function Chair() {
  return (
    <>
      <div className="circle"></div>
      <img src={chair2} alt="chair" className="lux-chair" />
    </>
  );
}

export default Chair;
