import { useReducer } from "react";
import ContactContext from "./contactContext";
import contactReducer from "./contactReducer";
import {
  SUBMIT_CONTACT,
  CONTACTS_LOADING,
  CLEAR_CONTACTS_LOADING,
} from "../types";
import toast from "react-hot-toast";
import axios from "axios";

import { apiUrl } from "../../utils/constants";

const ContactState = (props) => {
  const initialState = {
    loading: false,
  };

  const [state, dispatch] = useReducer(contactReducer, initialState);

  // Save contact
  const saveContact = async (contact, clearForm) => {
    try {
      setLoading();
      const res = await axios.post(`${apiUrl}/contacts`, contact);

      dispatch({
        type: SUBMIT_CONTACT,
        payload: res.data,
      });

      clearLoading();
      clearForm();
      toast.success(res.data.message);
    } catch (err) {
      console.error(err.response.data);
      toast.error(err.response.data.msg);
      clearLoading();
      clearForm();
    }
  };

  // Set Loading
  const setLoading = () => {
    dispatch({
      type: CONTACTS_LOADING,
    });
  };

  const clearLoading = () => {
    dispatch({
      type: CLEAR_CONTACTS_LOADING,
    });
  };
  return (
    <ContactContext.Provider
      value={{
        loading: state.loading,
        saveContact,
      }}
    >
      {props.children}
    </ContactContext.Provider>
  );
};

export default ContactState;
