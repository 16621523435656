import chair3 from "../../../Assets/img/chair4.png";

function Chair_2() {
  return (
    <>
      <img
        src={chair3}
        alt="chair"
        className="chair2InMusthave"
        style={{ position: "relative" }}
      />
    </>
  );
}

export default Chair_2;
