// import { useState } from "react";
import "./App.css";
import HOMEPAGE from "./Homepage";
import ALL_PRODUCTS_PAGE from "./Components/All_Product_page/All_Product_Page";
import CART from "./Components/Cart_page/Cart_page";
import { Routes, Route } from "react-router-dom";
import CartState from "./Context/cart/CartState";
import ContactState from "./Context/contact/ContactState";

function App() {
  // const [cart, setCart] = useState([]);
  return (
    <div className='App'>
      <ContactState>
        <CartState>
          <Routes>
            <Route path='home' element={<HOMEPAGE />} />
            <Route path='all_product' element={<ALL_PRODUCTS_PAGE />} />
            <Route path='cart_page' element={<CART />} />
            <Route path='*' element={<HOMEPAGE />} />
          </Routes>
        </CartState>
      </ContactState>
    </div>
  );
}

export default App;
